
* {
  direction: rtl;
}
.logo {
  display: block;
  margin: auto;
}

.title {
  text-align: center;
}
.subtitle {
  text-align: center;
}